<template>
  <div>
    <h1 class="pageHeader mb-md-10">{{ $t('label.whoAmI') }}</h1>
    <div class="pageContent py-5">
      <v-row no-gutters>
        <v-col cols="12" md="6" class="mobile-chkbox-mb">
          <p class="text-lg-h4 mobile-title-size">
            I'm <span class="font-weight-bold">talent</span>
          </p>
          <v-radio-group
            v-model="userType"
            :row="!$isMobile()"
            @change="$emit('input', userType)"
          >
            <v-radio value="talent" dark color="#FF2FF2">
              <template v-slot:label>
                <span class="white--text">Individual</span>
              </template>
            </v-radio>
            <v-radio value="agency" dark color="#FF2FF2">
              <template v-slot:label>
                <span class="white--text">Talent Agency</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="6">
          <p class="text-lg-h4 mobile-title-size">
            I'm <span class="font-weight-bold">looking for talent</span>
          </p>
          <v-radio-group
            v-model="userType"
            :row="!$isMobile()"
            @change="$emit('input', userType)"
          >
            <v-radio value="freelancer" dark color="#FF2FF2">
              <template v-slot:label>
                <span class="white--text">Freelance Individual</span>
              </template>
            </v-radio>
            <v-radio
              value="production_house"
              dark
              color="#FF2FF2"
              :class="$isMobile() ? 'mt-2' : null"
            >
              <template v-slot:label>
                <span class="white--text"
                  >Production House / Corporate / Agency</span
                >
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
    <div class="pageFooter py-5">
      <div class="text-center">
        <v-btn
          class="btn1"
          :disabled="!userType"
          @click="$emit('next', { role: userType })"
        >
          Next
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SignupRoleSelection',
    props: {
      role: {
        type: String,
      }
    },
    data() {
      return {
        userType: null
      };
    },
    mounted() {
      this.userType = this.role || null;
    }
  };
</script>

<style></style>
