import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"pageHeader mb-md-5"},[_vm._v(" "+_vm._s(_vm.$t('label.createPassword'))+" ")]),_c('p',{staticClass:"text-lg-h6 text-xl-h5"},[_vm._v(" "+_vm._s(_vm.$t('message.createAHighSecurityPassword'))+" ")]),_c('div',{staticClass:"pageContent py-5"},[_c(VForm,{ref:"form",model:{value:(_vm.createPasswordFormValid),callback:function ($$v) {_vm.createPasswordFormValid=$$v},expression:"createPasswordFormValid"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"space-between"}},[_c(VCol,{staticClass:"input-col",attrs:{"cols":"12","md":"6"}},[_c('AppFormField',{staticClass:"password-input",attrs:{"name":"password_id","label":_vm.$t('label.password'),"rules":_vm.concatRequiredRules(
                _vm.formValidation.passwordRules(_vm.formValues.password)
              ),"type":"password","allowShowPassword":_vm.hidePassword,"required":"","outlined":"","dark":""},model:{value:(_vm.formValues.password),callback:function ($$v) {_vm.$set(_vm.formValues, "password", $$v)},expression:"formValues.password"}})],1),_c(VCol,{staticClass:"input-col",attrs:{"cols":"12","md":"6"}},[_c('AppFormField',{staticClass:"password-input",attrs:{"name":"password_confirm_id","label":_vm.$t('label.passwordConfirmation'),"rules":_vm.concatRequiredRules(
                _vm.formValidation.confirmPasswordRules(
                  _vm.formValues.password,
                  _vm.formValues.passwordConfirmation
                )
              ),"type":"password","allowShowPassword":_vm.hideConfirmPassword,"required":"","outlined":"","dark":""},model:{value:(_vm.formValues.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formValues, "passwordConfirmation", $$v)},expression:"formValues.passwordConfirmation"}})],1)],1)],1)],1),_c('div',{staticClass:"py-5 pageFooter text-center"},[_c(VBtn,{staticClass:"btn1",attrs:{"disabled":!_vm.createPasswordFormValid},on:{"click":function($event){return _vm.$emit('next', _vm.formValues)}}},[_vm._v(" "+_vm._s(_vm.$t('label.next'))+" ")]),_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"underlineBtn",on:{"click":_vm.back}},[_vm._v(_vm._s(_vm.$t('label.back')))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }