<template>
  <div>
    <h1 class="pageHeader mb-md-10">
      {{ $t('label.moreAboutMe') }} (<u>{{ $t('label.optional') }}</u
      >)
    </h1>
    <div class="pageContent py-5">
      <v-form v-model="freelancerSignupFormValid" ref="agencyGeneralInfoForm">
        <v-row no-gutters justify="space-between">
          <v-col
            v-for="(formField, index) in agencySignupFormFields"
            :key="index"
            cols="12"
            :md="6"
            class="input-col"
          >
            <AppFormField
              v-if="formField.field !== 'other'"
              v-model="formValues[formField.key]"
              outlined
              dark
              :type="formField.formType"
              :height="40"
              :items="formField.options"
              :label="$t(`label.${formField.label}`)"
              :multiple="formField.multiple || false"
              itemColor="background"
              :color="formField.color || 'surface'"
              :class="`form-${formField.key}`"
              :rules="formField.rules"
            />
            <AppFormField
              v-if="
                (formField.key === 'descriptionOther' && isOtherDescription) ||
                (formField.key === 'pronounsOther' && includeOtherPronoun) ||
                (formField.key === 'genderOther' && isOtherGender)
              "
              v-model="formValues[formField.key]"
              dark
              :type="formField.formType"
              :label="$t(`label.${formField.label}`)"
              itemColor="background"
              :color="formField.color || 'surface'"
              :class="`form-${formField.key}`"
              :rules="formField.rules"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div class="pageFooter py-5 text-center">
      <v-btn class="btn1" @click="$emit('save', formValues)">
        {{ $t('label.createAccount') }}
      </v-btn>
      <div class="mt-5">
        <span class="underlineBtn" @click="back">{{ $t('label.back') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { i18nHelper, uiHelper, formValidationHelper } from '@/utils';
  import { listService, i18nService } from '@/services';
  import { LOCAL, SHARED } from '@/constants';

  export default {
    name: 'FreelancerSignupGeneralInfo',
    props: {
      signupDetails: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      formValues: {
        designation: null,
        description: null,
        descriptionOther: null,
        gender: null,
        genderOther: null,
        pronouns: null,
        pronounsOther: null,
        dob: null,
        countryId: null
      },
      freelancerSignupFormValid: false,
      currentLanguage: i18nService.getActiveLanguage()
    }),
    computed: {
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      agencySignupFormFields() {
        return [
          {
            key: 'designation',
            label: 'myDesignationIs',
            rules: formValidationHelper.requiredRules()
          },
          {
            field: 'other'
          },
          {
            key: 'description',
            label: 'weAreAAn',
            formType: 'select',
            options: listService.getDynamicList(
              this.constantsData.freelancer_description
            ),
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'descriptionOther',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'gender',
            label: 'gender',
            formType: 'select',
            options: listService.getDynamicList(this.constantsData.gender),
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.gender')
            ),
            outlined: false
          },
          {
            key: 'genderOther',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'pronouns',
            label: 'pronouns',
            formType: 'select',
            options: listService.getDynamicList(this.constantsData.pronouns),
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.pronouns')
            ),
            multiple: true,
            outlined: false
          },
          {
            key: 'pronounsOther',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'dob',
            label: 'dateOfBirth',
            formType: 'date',
            color: 'title',
            rules: formValidationHelper.dateRules(
              0,
              SHARED.DATE_TODAY,
              i18nHelper.getMessage('label.dateOfBirth')
            )
          },
          {
            key: 'countryId',
            label: 'countryOfResidence',
            formType: 'select',
            options: listService.getCountryList(
              this.constantsData.all_country,
              this.currentLanguage
            ),
            rules: formValidationHelper.requiredRules(
              'label.countryOfResidence'
            ),
            multiple: true
          }
        ];
      },
      isOtherDescription() {
        const otherValueKey = this.constantsData.freelancer_description.find(
          (x) => x.key === 'OTHER'
        ).value;
        return this.formValues.description === otherValueKey;
      },
      isOtherGender() {
        return this.formValues.gender === 'other';
      },
      includeOtherPronoun() {
        const otherPronounValueKey = this.constantsData.pronouns.find(
          (x) => x.key === 'OTHER'
        ).value;
        return (
          this.formValues?.pronouns?.find((x) => x === otherPronounValueKey) ??
          false
        );
      }
    },
    watch: {
      isOtherDescription(val) {
        if (!val) this.formValues.descriptionOther = null;
      },
      isOtherGender(val) {
        if (!val) this.formValues.genderOther = null;
      },
      includeOtherPronoun(val) {
        if (!val) this.formValues.pronounsOther = null;
      }
    },
    methods: {
      back() {
        this.$emit('back', {
          designation: null,
          description: null,
          descriptionOther: null,
          gender: null,
          genderOther: null,
          pronouns: null,
          pronounsOther: null,
          dob: null,
          countryId: null
        });
      }
    }
  };
</script>

<style></style>
