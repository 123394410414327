import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"pageHeader mb-md-10"},[_vm._v(" "+_vm._s(_vm.$t('label.moreAboutMe'))+" ("),_c('u',[_vm._v(_vm._s(_vm.$t('label.optional')))]),_vm._v(") ")]),_c('div',{staticClass:"pageContent py-5"},[_c(VForm,{ref:"agencyGeneralInfoForm",model:{value:(_vm.freelancerSignupFormValid),callback:function ($$v) {_vm.freelancerSignupFormValid=$$v},expression:"freelancerSignupFormValid"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"space-between"}},_vm._l((_vm.agencySignupFormFields),function(formField,index){return _c(VCol,{key:index,staticClass:"input-col",attrs:{"cols":"12","md":6}},[(formField.field !== 'other')?_c('AppFormField',{class:("form-" + (formField.key)),attrs:{"outlined":"","dark":"","type":formField.formType,"height":40,"items":formField.options,"label":_vm.$t(("label." + (formField.label))),"multiple":formField.multiple || false,"itemColor":"background","color":formField.color || 'surface',"rules":formField.rules},model:{value:(_vm.formValues[formField.key]),callback:function ($$v) {_vm.$set(_vm.formValues, formField.key, $$v)},expression:"formValues[formField.key]"}}):_vm._e(),(
              (formField.key === 'descriptionOther' && _vm.isOtherDescription) ||
              (formField.key === 'pronounsOther' && _vm.includeOtherPronoun) ||
              (formField.key === 'genderOther' && _vm.isOtherGender)
            )?_c('AppFormField',{class:("form-" + (formField.key)),attrs:{"dark":"","type":formField.formType,"label":_vm.$t(("label." + (formField.label))),"itemColor":"background","color":formField.color || 'surface',"rules":formField.rules},model:{value:(_vm.formValues[formField.key]),callback:function ($$v) {_vm.$set(_vm.formValues, formField.key, $$v)},expression:"formValues[formField.key]"}}):_vm._e()],1)}),1)],1)],1),_c('div',{staticClass:"pageFooter py-5 text-center"},[_c(VBtn,{staticClass:"btn1",on:{"click":function($event){return _vm.$emit('save', _vm.formValues)}}},[_vm._v(" "+_vm._s(_vm.$t('label.createAccount'))+" ")]),_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"underlineBtn",on:{"click":_vm.back}},[_vm._v(_vm._s(_vm.$t('label.back')))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }