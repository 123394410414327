<template>
  <div>
    <h1 class="pageHeader mb-md-2">{{ $t('label.guardiansInformation') }}</h1>
    <p class="text-lg-h6 text-xl-h5 mb-md-10">
      {{ $t('message.ifYouAreUnderTheAgeOf18') }}
    </p>
    <div class="pageContent py-5">
      <v-form
        v-model="guardianInfoFormValid"
        ref="guardianInfoForm"
        :class="[{ addOn: formValues.guardianRelationship == 'other' }]"
      >
        <v-row no-gutters justify="space-between" class="w-100 pb-12 pt-5">
          <v-col
            class="input-col"
            v-for="(formField, index) in guardianInfoFormFields"
            :key="index"
            cols="12"
            :md="formField.key === 'guardianGender' ? 12 : 6"
          >
            <AppFormField
              v-if="formField.field !== 'other'"
              v-model="formValues[formField.key]"
              outlined
              dark
              :type="formField.formType"
              :height="40"
              :items="formField.options"
              :label="$t(`label.${formField.label}`)"
              :multiple="formField.multiple || false"
              itemColor="background"
              :color="formField.color || 'surface'"
              :class="`form-${formField.key}`"
              :rules="formField.rules"
            />
            <AppFormField
              v-if="
                (formField.key === 'guardianRelationshipOther' &&
                  includeOtherGuardianRelationship) ||
                (formField.key === 'guardianPronounsOther' &&
                  includeOtherPronoun)
              "
              v-model="formValues[formField.key]"
              dark
              :type="formField.formType"
              :label="$t(`label.${formField.label}`)"
              itemColor="background"
              :color="formField.color || 'surface'"
              :class="`form-${formField.key}`"
            />

            <vue-tel-input-vuetify
              v-if="formField.key === 'guardianMobile'"
              :preferredCountries="['SG', 'MY']"
              defaultCountry="SG"
              v-model="formValues[formField.key]"
              :label="$t('label.mobileNo')"
              mode=""
              required
              outlined
              dark
              :rules="formField.rules"
              @input="onNumberChange"
              @country-changed="updateCountry"
            ></vue-tel-input-vuetify>
            <div v-if="formField.key === 'guardianGender'" class="mb-2">
              <div>{{ $t('label.gender') }}</div>
              <v-radio-group
                v-model="formValues.guardianGender"
                row
                class="d-inline-block"
              >
                <v-radio
                  dark
                  color="#FF2FF2"
                  v-for="(gender, genderIndex) in constantsData.gender"
                  :value="gender.value"
                  :key="genderIndex"
                >
                  <template v-slot:label>
                    <span class="white--text">{{ gender.description }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
              <CustomInput
                v-if="formValues.guardianGender === 'other'"
                v-model="formValues.guardianGenderOther"
                class="d-inline-block ps-5"
                style="width: 40%"
                id="gender-other-id"
                dark
                type="text"
                :label="$t('label.pleaseSpecify')"
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
      <hr class="divider my-5" />
      <div class="pt-4 pb-12">
        <AppCheckBox
          customisedLabel
          v-model="agreeConsent"
          class="justify-center"
        >
          I have read and understood the
          <a href="/guardian-consent" target="_blank">{{
            $t('label.acknowledgeAndConsent')
          }}</a>
        </AppCheckBox>
      </div>
    </div>
    <div class="py-5 pageFooter text-center">
      <v-btn class="btn1" @click="$emit('save', formValues)">
        {{ $t('label.createAccount') }}
      </v-btn>
      <div class="mt-5 text-center">
        <a text @click="back">
          {{ $t('label.backToTalentForm') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { i18nHelper, uiHelper, formValidationHelper } from '@/utils';
  import customFormValidationMixin from '@/mixin/custom-form-validation.mixin';
  import { LOCAL } from '@/constants';
  import { listService, i18nService } from '@/services';
  import CustomInput from '@/components/inputs/custom-input.vue';
  import AppCheckBox from '@/components/base/AppCheckBox';

  export default {
    name: 'SignupGuardianForm',
    mixins: [customFormValidationMixin],
    props: {
      info: {
        type: Object,
        default: () => ({})
      }
    },
    components: {
      CustomInput,
      AppCheckBox
    },
    data: () => ({
      formValues: {
        guardianRelationship: null,
        guardianRelationshipOther: null,
        guardianFirstName: null,
        guardianLastName: null,
        guardianGender: null,
        guardianGenderOther: null,
        guardianPronouns: null,
        guardianPronounsOther: null,
        guardianMobileDialCode: '+65',
        guardianMobile: null,
        guardianEmail: null
      },
      guardianInfoFormValid: false,
      currentLanguage: i18nService.getActiveLanguage(),
      agreeConsent: false
    }),
    computed: {
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      guardianInfoFormFields() {
        return [
          {
            key: 'guardianRelationship',
            label: 'relationshipWithTalent',
            formType: 'select',
            options: listService.getDynamicList(
              this.constantsData.guardian_relationship
            ),
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardianRelationshipOther',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardianFirstName',
            label: 'firstName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.firstName'),
              2,
              50
            )
          },
          {
            key: 'guardianLastName',
            label: 'lastName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.lastName'),
              2,
              50
            )
          },
          {
            key: 'guardianMobile',
            label: 'mobileNo',
            field: 'other',
            rules: this.concatRequiredRules(
              formValidationHelper.mobileNumberRules(
                this.formValues.guardianMobileDialCode,
                this.formValues.guardianMobile
              )
            )
          },
          {
            key: 'guardianEmail',
            label: 'email',
            rules: this.concatRequiredRules(formValidationHelper.emailRules())
          },
          {
            key: 'guardianGender',
            label: 'guardianGender',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardianPronouns',
            label: 'pronouns',
            formType: 'select',
            options: listService.getDynamicList(this.constantsData.pronouns),
            rules: formValidationHelper.requiredRules(),
            multiple: true
          },
          {
            key: 'guardianPronounsOther',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          }
        ];
      },
      phoneCodes() {
        return listService.getPhoneCodeList(this.constantsData.country);
      },
      includeOtherGuardianRelationship() {
        return this.formValues.guardianRelationship === 'other';
      },
      includeOtherPronoun() {
        const otherPronounValueKey = this.constantsData.pronouns.find(
          (x) => x.key === 'OTHER'
        ).value;
        return (
          this.formValues?.guardianPronouns?.find(
            (x) => x === otherPronounValueKey
          ) ?? false
        );
      }
    },
    methods: {
      onNumberChange(v, v2) {
        this.formValues.guardianMobile = v2.number.significant;
      },
      updateCountry(event) {
        this.formValues.guardianMobileDialCode = '+' + event.dialCode;
      },
      back() {
        this.$emit('back', {
          guardianRelationship: null,
          guardianRelationshipOther: null,
          guardianFirstName: null,
          guardianLastName: null,
          guardianGender: null,
          guardianGenderOther: null,
          guardianPronouns: null,
          guardianPronounsOther: null,
          guardianMobileDialCode: '+65',
          guardianMobile: null,
          guardianEmail: null
        });
      }
    },
    watch: {
      // gender(val) {
      //   if (val !== 'other') this.formValues.genderOther = null;
      // },
      includeOtherGuardianRelationship(val) {
        if (!val) this.formValues.guardianRelationshipOther = null;
      },
      includeOtherPronoun(val) {
        if (!val) this.formValues.guardianPronounsOther = null;
      }
    }
  };
</script>

<style scoped></style>
