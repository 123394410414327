<template>
  <div>
    <template v-if="!isInputOTP">
      <h1 class="pageHeader mb-md-10">{{ $t('label.moreAboutMe') }}</h1>
      <div class="pageContent py-5">
        <v-form ref="form" class="signupForm" v-model="signupFieldValidation">
          <v-row no-gutters justify="space-between">
            <v-col cols="12" md="6" class="input-col">
              <CustomInput
                v-model="formValues.firstName"
                name="signup_firstname"
                :label="$t('label.firstName')"
                type="text"
                :rules="formValidation.requiredRules()"
                :config="{
                  hideDetails: false
                }"
                required
                outlined
                dark
              />
            </v-col>
            <v-col cols="12" md="6" class="input-col">
              <CustomInput
                v-model="formValues.lastName"
                name="signup_lastname"
                :label="$t('label.lastName')"
                type="text"
                :config="{
                  hideDetails: false
                }"
                :rules="formValidation.requiredRules()"
                required
                outlined
                dark
              />
            </v-col>
            <v-col cols="12" md="6" class="input-col">
              <vue-tel-input-vuetify
                :preferredCountries="['SG', 'MY']"
                :defaultCountry="mobileDialCodeCountry"
                v-model="formValues.mobile"
                :label="$t('label.mobileNo')"
                mode=""
                required
                outlined
                dark
                :rules="
                  formValidation.mobileNumberRules(
                    formValues.mobileDialCode,
                    formValues.mobile
                  )
                "
                @input="onNumberChange"
                @country-changed="updateCountry"
              ></vue-tel-input-vuetify>
            </v-col>
            <v-col cols="12" md="6" class="input-col">
              <CustomInput
                v-model="formValues.email"
                name="signup_email"
                :label="$t('label.email')"
                type="text"
                :config="{
                  hideDetails: false
                }"
                :rules="formValidation.emailRules()"
                required
                outlined
                dark
              />
            </v-col>
            <v-col cols="12" md="12">
              <div class="grey--text">
                {{ $t('label.pleaseSelectOneOfTheseOptions') }}
              </div>
              <div>
                <v-radio-group
                  v-model="formValues.getotpby"
                  :row="!$isMobile()"
                >
                  <v-radio
                    dark
                    :label="$t('label.loginViaMobileNumber')"
                    value="mobile"
                    color="primary"
                  ></v-radio>
                  <v-radio
                    dark
                    :label="$t('label.loginViaEmail')"
                    value="email"
                    color="primary"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <hr class="divider my-5" />
        <div class="pt-4 pb-12">
          <AppCheckBox
            customisedLabel
            v-model="agreeTNC"
            class="justify-center"
          >
            By signing up, I agree to the
            <a href="/terms-of-use" target="_blank">{{
              $t('label.termsOfUse2')
            }}</a>
            &
            <a href="/privacy-policy" target="_blank">{{
              $t('label.privacyPolicy')
            }}</a>
          </AppCheckBox>
        </div>
      </div>
      <div class="py-5 pageFooter text-center">
        <v-btn class="btn1" :disabled="!formValid" @click="checkUserExist">
          Send OTP
        </v-btn>
        <div class="mt-5">
          <span class="underlineBtn" @click="back">{{ $t('label.back') }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <h1 class="pageHeader mb-md-10">{{ $t('label.enterOTP') }}</h1>
      <p class="text-xl-h6 text-lg-h5">
        {{
          formValues.getotpby === 'mobile'
            ? $t('message.aSixDigitCodeHasBeenSentViaSMSTo')
            : $t('message.aSixDigitCodeHasBeenSentViaEmailTo')
        }}
        {{ formValues.getotpby === 'mobile' ? phoneNumber : formValues.email }}
      </p>
      <p class="text-lg-h6 text-xl-h5 pink2--text">
        {{ $t('label.resendCodeIn') }} <span id="timer"></span>
      </p>

      <div class="pageContent pb-5">
        <v-row no-gutters justify="space-between">
          <v-col cols="12" md="4" sm="6">
            <v-otp-input
              class="otp-input"
              dark
              length="6"
              type="number"
              v-model="otp"
              @finish="verifyOTPAction"
            >
            </v-otp-input>
          </v-col>
        </v-row>
      </div>
      <div class="text-center pageFooter py-5">
        <v-btn
          class="btn1"
          :disabled="isResendDisabled"
          @click="resendOTPAction"
        >
          {{ $t('label.resendOTP') }}
        </v-btn>
        <div class="mt-5 text-center">
          <a @click="changeMobileNoAction">
            {{
              formValues.getotpby == 'mobile'
                ? $t('label.changeMobileNo')
                : $t('label.changeEmail')
            }}
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { i18nHelper, formValidationHelper } from '@/utils';
  import CustomInput from '@/components/inputs/custom-input.vue';
  import AppCheckBox from '@/components/base/AppCheckBox';
  import constantsCountry from '@/filters/constants-country.filter';
  import {
    AUTH_REQUEST_OTP,
    AUTH_VERIFY_OTP,
    AUTH_CHECK_USER_EXISTENCE,
    AUTH_INITIAL_REQUEST_OTP_STATE,
    AUTH_INITIAL_VERIFY_OTP_STATE,
    AUTH_INITIAL_CHECK_USER_EXISTENCE
  } from '@/store/auth.module';

  export default {
    name: 'SignupLoginMethod',
    components: {
      CustomInput,
      AppCheckBox
    },
    props: {
      info: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        formValues: {
          getotpby: 'mobile',
          firstName: null,
          lastName: null,
          mobileDialCode: '+65',
          mobile: '',
          email: null
        },
        sendOtpClick: false,
        signupFieldValidation: false,
        agreeTNC: false,
        formValidation: formValidationHelper,
        isInputOTP: false,
        isResendDisabled: false,
        otp: null
      };
    },
    watch: {
      requestOTPComplete() {
        let response = this.$store.state.auth.requestOTP;

        if (response.complete) {
          this.requestOTPCompleteAction(response);
        }
      },
      verifyOTPComplete() {
        let response = this.$store.state.auth.verifyOTP;

        if (response.complete) {
          this.verifyOTPCompleteAction(response);
        }
      },
      checkUserExistenceComplete() {
        let response = this.$store.state.auth.checkUserExist;

        if (response.complete && this.sendOtpClick === true) {
          this.checkUserExistCompleteAction(response);
        }
      },
      isInputOTP() {
        this.$scrollToTop();
      }
    },
    computed: {
      formValid() {
        let form = ['getotpby', 'firstName', 'lastName'];
        if (this.formValues.getotpby == 'mobile')
          form.push('mobileDialCode', 'mobile');
        else form.push('email');
        return form.every((x) => this.formValues[x]) && this.agreeTNC;
      },
      mobileDialCodeCountry() {
        const constCountry = constantsCountry(
          this.formValues.mobileDialCode,
          'getConstantsByPhoneCode'
        );

        return constCountry.abbr || null;
      },
      requestOTPData() {
        return {
          auth_method: this.formValues.getotpby,
          mobileDialCode: this.formValues.mobileDialCode,
          mobile: this.formValues.mobile,
          email: this.formValues.email,
          otpType: 'register'
        };
      },
      requestOTPComplete() {
        return this.$store.state.auth.requestOTP.complete;
      },
      verifyOTPComplete() {
        return this.$store.state.auth.verifyOTP.complete;
      },
      checkUserExistenceComplete() {
        return this.$store.state.auth.checkUserExist.complete;
      },
      phoneNumber() {
        let trimCount = {
          '+65': 4,
          '+60': 5
        };
        let hiddenCount = {
          '+65': 'XXXX',
          '+60': 'XXXXX'
        };

        if (this.formValues) {
          return `${this.formValues.mobileDialCode}-${
            hiddenCount[this.formValues.mobileDialCode]
          } ${this.formValues.mobile.substr(
            trimCount[this.formValues.mobileDialCode]
          )}`;
        } else {
          return '+65-XXXXXXX';
        }
      }
    },
    methods: {
      onNumberChange(v, v2) {
        this.formValues.mobile = v2.number.significant;
      },
      updateCountry(event) {
        this.formValues.mobileDialCode = '+' + event.dialCode;
      },
      verifyOTPAction() {
        const data = {
          ...this.requestOTPData,
          code: this.otp
        };

        this.verifyOTP(data);
      },
      changeMobileNoAction() {
        this.isInputOTP = false;
        if (this.formValues.getotpby === 'mobile') {
          this.formValues.mobile = '';
        } else {
          this.formValues.email = '';
        }
      },
      verifyOTPCompleteAction(response) {
        if (response.code == 200) {
          this.$emit('next', this.formValues);
        } else {
          this.openAppDialogInfo(
            'error',
            i18nHelper.getMessage('label.verifyOTP'),
            response.message,
            [
              {
                color: 'title',
                text: i18nHelper.getMessage('label.ok'),
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.initialVerifyOTP();
      },
      requestOTPCompleteAction(response) {
        if (response.code == 200) {
          this.$nextTick(function () {
            this.setResendOTPTimer();
            this.isResendDisabled = true;
          });
        }

        this.initialRequestOTP();
      },
      checkUserExistCompleteAction(response) {
        if (response.data.is_registered && response.data.is_activated) {
          alert(
            'This mobile number/email address has been taken, please try another'
          );
        } else {
          this.requestOTP(this.requestOTPData);
          this.isInputOTP = true;
        }

        this.initialCheckUserExist();
      },
      async checkUserExist() {
        const data = {
          auth_method: this.formValues.getotpby,
          mobile_dial_code: this.formValues.mobileDialCode,
          mobile: this.formValues.mobile,
          email: this.formValues.email
        };
        this.sendOtpClick = true;
        this.$store.dispatch(AUTH_CHECK_USER_EXISTENCE, { data });
      },

      requestOTP(data) {
        this.$store.dispatch(AUTH_REQUEST_OTP, { data });
      },
      resendOTPAction() {
        this.requestOTP(this.requestOTPData);
      },
      initialRequestOTP() {
        this.$store.dispatch(AUTH_INITIAL_REQUEST_OTP_STATE);
      },
      initialCheckUserExist() {
        this.sendOtpClick = false;
        this.$store.dispatch(AUTH_INITIAL_CHECK_USER_EXISTENCE);
      },
      verifyOTP(data) {
        this.$store.dispatch(AUTH_VERIFY_OTP, { data });
      },
      initialVerifyOTP() {
        this.$store.dispatch(AUTH_INITIAL_VERIFY_OTP_STATE);
      },
      setResendOTPTimer() {
        let vm = this;
        document.getElementById('timer').innerHTML = 1 + ':' + 0;

        startTimer();

        function startTimer() {
          var presentTime = document.getElementById('timer').innerHTML;
          var timeArray = presentTime.split(/[:]+/);
          var m = timeArray[0];
          var s = checkSecond(timeArray[1] - 1);
          if (s == 59) {
            m = m - 1;
          }
          if (m < 0) {
            return;
          }

          document.getElementById('timer').innerHTML = m + ':' + s;

          if (m == '0' && s == '00') {
            vm.isResendDisabled = false;
          }

          setTimeout(startTimer, 1000);
        }

        function checkSecond(sec) {
          if (sec < 10 && sec >= 0) {
            sec = '0' + sec;
          } // add zero in front of numbers < 10
          if (sec < 0) {
            sec = '59';
          }
          return sec;
        }
      },
      back() {
        this.$emit('back', {
          getotpby: 'mobile',
          firstName: null,
          lastName: null,
          mobileDialCode: '+65',
          mobile: '',
          email: null
        });
      }
    },
    mounted() {
      this.formValues = {
        getotpby: this.info?.getotpby || 'mobile',
        firstName: this.info?.firstName || null,
        lastName: this.info?.lastName || null,
        mobileDialCode: this.info?.mobileDialCode || '+65',
        mobile: this.info?.mobile || '',
        email: this.info?.email || null
      };
    }
  };
</script>

<style scoped lang="scss">
  // .input-col > div {
  // 	padding: 0 .5rem;
  // }

  .otp-input {
    ::v-deep .v-input__slot {
      color: #ff2ff2;
      background: transparent !important;
      min-height: 80px;
    }
  }

  .authMethodCheckbox {
    ::v-deep .theme--dark.v-label {
      color: white;
    }
  }
</style>
