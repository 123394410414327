<template>
  <div class="page-container pb-12">
    <v-img
      src="/assets/image/icons/leftBar.png"
      class="leftBar d-none d-lg-block d-xl-none"
    />
    <v-img
      src="/assets/image/icons/rightBar.png"
      class="rightBar d-none d-lg-block d-xl-none"
    />
    <div
      class="headerStep"
      :class="currentStep == steps.length - 1 ? 'active' : ''"
    >
      <div class="container">
        <div class="headerScroll d-flex">
          <div
            v-for="(step, i) in steps"
            :key="i"
            class="headerItem noClickAction"
            :class="i <= currentStep ? 'active' : ''"
          >
            Step {{ i + 1 }} - {{ step }}
          </div>
        </div>
      </div>
    </div>
    <div class="container py-10">
      <SignupRoleSelection
        v-if="currentStep == 0"
        :role="formValues.role"
        @next="next"
      />
      <SignupLoginMethod
        v-if="currentStep == 1"
        :info="formValues"
        @next="next"
        @back="back"
      />
      <SignupCreatePassword
        v-if="currentStep == 2"
        :info="formValues"
        @next="create"
        @back="back"
      />
      <template v-if="currentStep == 3">
        <SignupTalentForm
          v-if="formValues.role == 'talent' && !showGuardianForm"
          :info="formValues"
          @back="back"
          @proceedToGuardian="next($event, true)"
          @save="updateDetail"
        />
        <SignupGuardianForm
          v-if="formValues.role == 'talent' && showGuardianForm"
          @back="
            back($event, true);
            showGuardianForm = false;
          "
          @save="updateDetail"
        />
        <SignupAgencyForm
          v-if="formValues.role == 'agency'"
          @back="back"
          @save="updateDetail"
        />
        <SignupFreelancerForm
          v-if="formValues.role == 'freelancer'"
          @back="back"
          @save="updateDetail"
        />
        <SignupProductionHouseForm
          v-if="formValues.role == 'production_house'"
          @back="back"
          @save="updateDetail"
        />
      </template>
    </div>
    <ModalBase width="500" id="afterCreateModal" persistent noCloseButton>
      <div>
        <div class="text-center text-uppercase my-5 font-weight-bold lg-font">
          Account is created.
        </div>
        <div class="text-center pt-8">
          <v-btn class="btn1 mb-4" @click="afterCreate">
            {{ $t('label.proceed') }}
          </v-btn>
        </div>
      </div>
    </ModalBase>
    <ModalBase width="500" id="redirectModal" persistent noCloseButton>
      <div>
        <div class="text-center text-uppercase my-5 font-weight-bold lg-font">
          Account updated successfully.
        </div>
        <div class="text-center pt-8">
          <v-btn class="btn1 mb-4" @click="login">
            {{ $t('label.login') }}
          </v-btn>
        </div>
      </div>
    </ModalBase>
  </div>
</template>
<script>
  import { ROUTE_NAME } from '@/constants';
  import SignupRoleSelection from '@/components/Signup/SignupRoleSelection.vue';
  import SignupLoginMethod from '@/components/Signup/SignupLoginMethod.vue';
  import SignupCreatePassword from '@/components/Signup/SignupCreatePassword.vue';
  import SignupTalentForm from '@/components/Signup/SignupTalentForm.vue';
  import SignupGuardianForm from '@/components/Signup/SignupGuardianForm.vue';
  import SignupAgencyForm from '@/components/Signup/SignupAgencyForm.vue';
  import SignupFreelancerForm from '@/components/Signup/SignupFreelancerForm.vue';
  import SignupProductionHouseForm from '@/components/Signup/SignupProductionHouseForm.vue';
  import ModalBase from '@/components/base/ModalBase';
  import {
    AUTH_LOGIN,
    AUTH_IS_AUTHENTICATED,
    AUTH_RESET_LOGIN_STATE,
    AUTH_ME
  } from '@/store/auth.module';
  import { mapGetters } from 'vuex';
  import { AUTH } from '@/api';

  export default {
    name: 'Signup',
    components: {
      SignupRoleSelection,
      SignupLoginMethod,
      SignupCreatePassword,
      SignupTalentForm,
      SignupGuardianForm,
      SignupAgencyForm,
      SignupFreelancerForm,
      SignupProductionHouseForm,
      ModalBase
    },
    data: () => ({
      steps: [
        'Choose Role',
        'Choose Login Method',
        'Create Password',
        'Fill in General Info'
      ],
      currentStep: 0,
      routeName: ROUTE_NAME,
      formValues: {
        role: 'talent'
      },
      usedFormKeys: [],
      showGuardianForm: false
    }),
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED]
      }),
      loginComplete() {
        return this.$store.state.auth.loginResponse.complete;
      },
      registerRole() {
        let register = '';
        switch (this.formValues.role) {
          case 'talent':
            register = 'registerTalent';
            break;
          case 'agency':
            register = 'registerAgency';
            break;
          case 'production_house':
            register = 'registerProductionHouse';
            break;
          case 'freelancer':
            register = 'registerFreelancer';
            break;
        }
        return register;
      }
    },
    watch: {
      currentStep() {
        this.$scrollToTop();
      },
      async loginComplete() {
        let response = this.$store.state.auth.loginResponse;
        if (response.complete) {
          this.loginCompleteAction(response);
        }
      }
    },
    methods: {
      next(e, proceedToGuardian = false) {
        this.formValues = {
          ...this.formValues,
          ...e
        };
        if (proceedToGuardian) this.showGuardianForm = true;
        if (this.currentStep < 3) this.currentStep++;
      },
      back(e, noStepIncrement = false) {
        this.formValues = {
          ...this.formValues,
          ...e
        };
        if (!noStepIncrement) this.currentStep--;
      },
      async create(e) {
        this.formValues = {
          ...this.formValues,
          ...e
        };
        try {
          const createPayload = this.formValues;
          await AUTH[this.registerRole](createPayload);
          this.usedFormKeys = Object.keys(createPayload);
          this.$openModal('afterCreateModal');
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      async updateDetail(e) {
        this.next(e);
        this.$startLoading();
        try {
          const updatePayload = Object.fromEntries(
            Object.entries(this.formValues)
              .filter(([key]) => !this.usedFormKeys.includes(key))
              .map(([key, value]) => [
                key.replace(/([A-Z])/g, '_$1').toLowerCase(),
                value
              ])
          );
          await AUTH.updateDetails(updatePayload);
          this.$openModal('redirectModal');
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      afterCreate(e) {
        if (e) e.preventDefault();
        this.currentStep++;
        this.$closeModal();
      },
      login(e) {
        if (e) e.preventDefault();
        const data = {
          auth_method: this.formValues.getotpby,
          email: this.formValues.email,
          mobile: this.formValues.mobile,
          mobile_dial_code: this.formValues.mobileDialCode,
          password: this.formValues.password
        };
        this.$store.dispatch(AUTH_LOGIN, { data });
      },
      async loginCompleteAction(response) {
        if (response.code == 200 && this.isAuthenticated) {
          this.$store.dispatch(AUTH_ME);
          this.$router.push('/');
          this.$closeModal();
        } else {
          this.openAppDialogInfo(
            'error',
            response.message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.initialLoginState();
                  this.closeAppDialogInfo();
                  this.$router.push('/');
                }
              }
            ]
          );
        }
      },
      initialLoginState() {
        this.$store.dispatch(AUTH_RESET_LOGIN_STATE);
      }
    }
  };
</script>
<style scoped lang="scss">
  .v-radio {
    align-items: flex-start;
    padding-bottom: 10px;
  }
  a {
    text-decoration: underline;
  }
  @media (max-width: 959px) {
    .mobile-chkbox-mb {
      margin-bottom: 1rem;
    }
    .mobile-title-size {
      font-size: 1.1rem;
    }
  }
</style>
