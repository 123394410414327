<template>
  <div>
    <h1 class="pageHeader mb-md-5">
      {{ $t('label.createPassword') }}
    </h1>
    <p class="text-lg-h6 text-xl-h5">
      {{ $t('message.createAHighSecurityPassword') }}
    </p>
    <div class="pageContent py-5">
      <v-form v-model="createPasswordFormValid" ref="form">
        <v-row no-gutters justify="space-between">
          <v-col cols="12" md="6" class="input-col">
            <AppFormField
              v-model="formValues.password"
              class="password-input"
              name="password_id"
              :label="$t('label.password')"
              :rules="
                concatRequiredRules(
                  formValidation.passwordRules(formValues.password)
                )
              "
              type="password"
              :allowShowPassword="hidePassword"
              required
              outlined
              dark
            />
          </v-col>
          <v-col cols="12" md="6" class="input-col">
            <AppFormField
              v-model="formValues.passwordConfirmation"
              class="password-input"
              name="password_confirm_id"
              :label="$t('label.passwordConfirmation')"
              :rules="
                concatRequiredRules(
                  formValidation.confirmPasswordRules(
                    formValues.password,
                    formValues.passwordConfirmation
                  )
                )
              "
              type="password"
              :allowShowPassword="hideConfirmPassword"
              required
              outlined
              dark
            />
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div class="py-5 pageFooter text-center">
      <v-btn
        class="btn1"
        :disabled="!createPasswordFormValid"
        @click="$emit('next', formValues)"
      >
        {{ $t('label.next') }}
      </v-btn>
      <div class="mt-5">
        <span class="underlineBtn" @click="back">{{$t('label.back')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { formValidationHelper } from '@/utils';
  import customFormValidationMixin from '@/mixin/custom-form-validation.mixin';

  export default {
    name: 'SignupCreatePassword',
    mixins: [customFormValidationMixin],
    props: {
      info: {
        type: Object,
        default: () => ({})
      },
    },
    data: () => ({
      formValues: {
        password: null,
        passwordConfirmation: null
      },
      formValidation: formValidationHelper,
      createPasswordFormValid: false,
      hidePassword: true,
      hideConfirmPassword: true
    }),
    methods: {
      back() {
        this.$emit('back', {
          password: null,
          passwordConfirmation: null
        })
      }
    },
    mounted() {
      this.formValues = {
        password: this.info?.password || null,
        passwordConfirmation: this.info?.passwordConfirmation || null,
      }
    }
  };
</script>

<style></style>
